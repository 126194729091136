var preventClick = false;
var resizeTimeout;


function checkMenu() {

	if( $(window).width() <= 1000 ) {
		$( 'ul#menu li.has_submenu' ).find( 'ul' ).addClass( 'closed_menu' );
		preventClick = true;
	}
	else {
		preventClick = false;
	}
	return;
}

function makeSameHeight(selector, includeMargin = true) {
	var minHeight = 0;
	$(selector).each(function(){
		var itemHeight = $(this).outerHeight(includeMargin);
		if(itemHeight > minHeight ) {
			minHeight = itemHeight;
		}
	});
	$(selector).css('min-height', minHeight );
}

$(window).scroll(function() {
	var scroll = $(window).scrollTop();
	if( scroll >= 500 ) {
		$( "#back-top" ).addClass( "active" );
	}
	else {
		$( "#back-top" ).removeClass( "active" );
	}
});

$(document).ready(function() {

	const header = document.getElementById("header");
	const intercept = document.createElement("div");

	intercept.setAttribute("data-observer-intercept", "");
	header.before(intercept);

	const observer = new IntersectionObserver(([entry]) => {
		header.classList.toggle("active", !entry.isIntersecting);
	});

	observer.observe(intercept);

	if( window.location.hash ) {
		$(window).scrollTop(0);
		scrollDownToPage( window.location.hash.substr(1) );
	}

	$( '#back-top a' ).click(function(e) {
		$('html,body').animate({ scrollTop: 0}, 300);
	});


	window.onresize = function() {
		clearTimeout(resizeTimeout);
		// handle normal resize
		resizeTimeout = setTimeout(function() {
			checkMenu();
		}, 250);
	};

	checkMenu();

	$('ul#menu li.has_submenu').bind('click', function(e) {

		if( preventClick ) {
			if( $(this).hasClass( 'clicked_menu' ) === false ) {
				e.preventDefault();

				$(this).children( 'ul' ).addClass( 'open_menu' );
				$(this).addClass( 'clicked_menu' );
			}
		}
	});

	// Gets the video src from the data-src on each button

	var $videoSrc;
	$('.video-btn').click(function() {
			$videoSrc = $(this).data( "src" );
	});
	console.log($videoSrc);

	// when the modal is opened autoplay it
	$('#myModal').on('shown.bs.modal', function (e) {

	// set the video src to autoplay and not to show related video. Youtube related video is like a box of chocolates... you never know what you're gonna get
	$("#video").attr('src',$videoSrc + "?autoplay=1&amp;modestbranding=1&amp;showinfo=0");

	})

	// stop playing the youtube video when I close the modal
	$('#myModal').on('hide.bs.modal', function (e) {
			// a poor man's stop video
			$("#video").attr('src',$videoSrc);
	})

});


function scrollDownToPage( anchor ) {

	if ( ( anchor ) > -1 )
	{

		var headerHeight = $('#header').outerHeight();
		var scrollDown = ( $('#' + anchor).offset().top - headerHeight - 40 );
		setTimeout(function () {
			if ($(this).scrollTop() == 0) {
				$('html,body').animate({scrollTop: scrollDown}, 800);
			}
		}, 800); // four seconds

	}

	return;
}